import { Category, RadioLiveMetadata } from "services-hooks/types";

export const findCategory = (categories: Category[], category: Category) => {
  return categories.find(
    (categoryItem) => categoryItem.permalink === category.permalink
  );
};

export const getStationLiveLogo = (
  metadata: RadioLiveMetadata | undefined,
  fallback: string
) => {
  if (metadata?.body?.content) {
    if (metadata.body?.content?.track?.cover) {
      return metadata.body?.content?.track?.cover;
    }
    if (metadata.body?.content?.track?.album?.cover) {
      return metadata.body?.content?.track?.album?.cover;
    }
    if (metadata.body?.content?.show?.logo) {
      return metadata.body?.content?.show?.logo;
    }
  }
  return fallback;
};

export const getStationTitle = (
  metadata: RadioLiveMetadata,
  stationName: string
) => {
  if (metadata.body?.content?.show?.name) {
    return `${stationName} - ${metadata.body?.content?.show?.name}`;
  } else if (metadata.body?.content?.track?.name) {
    return `${stationName} - ${metadata.body?.content?.track?.name}`;
  } else {
    return stationName;
  }
};

export const getArtistName = (metadata: RadioLiveMetadata) => {
  if (metadata.body?.content) {
    if (metadata.body?.content?.track?.artist?.name) {
      return metadata.body?.content?.track?.artist?.name;
    }
  }
};
